<template>
  <img
    v-if="isImage"
    :src="value"
    :width="size"
    height="auto"
    class="mr-1"
  />
  <b-icon
    v-else
    :icon="value"
    class="mr-1"
    :custom-size="`mdi-${size}`">
  </b-icon>
</template>

<script>
export default {
  props: {
    value: String,
    size: {
      type: String,
      default: '40px'
    }
  },
  computed: {
    isImage () {
      return this.value.startsWith('/') ||
        this.value.startsWith('data:image') ||
        this.value.startsWith('http')
    }
  }
}
</script>

<style>

</style>
